import { useDayjs } from '#dayjs'
const dayjs = useDayjs()

export const formatDate = (date: Date | string | undefined, format = 'DD.MM.YYYY'): string => {
  if (dayjs(date).isValid()) {
    return dayjs(date).format(format)
  }
  return ''
}

export function getFullDate(date: Date | string | undefined) {
  return dayjs(date).format('D MMMM YYYY г.')
}

export const daysOfWeeks = [
  {
    long: 'Воскресенье',
    short: 'Вс',
    eng: 'sunday'
  },
  {
    long: 'Понедельник',
    short: 'Пн',
    eng: 'monday'
  },
  {
    long: 'Вторник',
    short: 'Вт',
    eng: 'tuesday'
  },
  {
    long: 'Среда',
    short: 'Ср',
    eng: 'wednesday'
  },
  {
    long: 'Четверг',
    short: 'Чт',
    eng: 'thursday'
  },
  {
    long: 'Пятница',
    short: 'Пт',
    eng: 'friday'
  },
  {
    long: 'Суббота',
    short: 'Сб',
    eng: 'saturday'
  }
]

export const monthOfYear = [
  {
    long: 'Январь',
    short: 'Янв'
  },
  {
    long: 'Февраль',
    short: 'Фев'
  },
  {
    long: 'Март',
    short: 'Мар'
  },
  {
    long: 'Апрель',
    short: 'Апр'
  },
  {
    long: 'Май',
    short: 'Май'
  },
  {
    long: 'Июнь',
    short: 'Июн'
  },
  {
    long: 'Июль',
    short: 'Июл'
  },
  {
    long: 'Август',
    short: 'Авг'
  },
  {
    long: 'Сентябрь',
    short: 'Сен'
  },
  {
    long: 'Октябрь',
    short: 'Окт'
  },
  {
    long: 'Ноябрь',
    short: 'Ноя'
  },
  {
    long: 'Декабрь',
    shor: 'Дек'
  }
]
